import { template as template_3b6a4ed0431a441cb538d457858e1428 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3b6a4ed0431a441cb538d457858e1428(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
