import { template as template_98e5c63998d04b8595207a18a02d4f0c } from "@ember/template-compiler";
const WelcomeHeader = template_98e5c63998d04b8595207a18a02d4f0c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
